export default {
  data() {
    return {
      user: null
    }
  },
  mounted() {
    this.user = this.$store.state.auth.user
  },
  methods: {
    checkPermission(permission) {
      return this.user && _.get(this.user, permission)
    },
    getPermissionClass(permission) {
      return permission !== 'false' && this.checkPermission(permission)
        ? ''
        : 'disableChoice'
    },
    menuLinkClicked(permission, link) {
      if (permission !== 'false' && this.checkPermission(permission)) {
        this.$router.push(link)
      }
    }
  }
}
