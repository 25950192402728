<script>
import menuMixins from './mixins/menu'

export default {
  mixins: [menuMixins],
  data() {
    return {
      journalMenus: [
        {
          title: 'สมุดรายวัน - ป้อนเอง',
          link: '/entry/ge2gjnl?mode=user',
          permission: 'permission.entryJournal.canView'
        },
        {
          title: 'สมุดรายวัน - โดยระบบ',
          link: '/entry/ge2gjnl?mode=system',
          permission: 'permission.entryJournal.canView'
        },
        {
          title: 'สร้างต้นแบบวิธีการบันทึก',
          link: '/entry/ge2gjnl/journal-document-templates',
          permission: 'permission.entryJournalTemplate.canView'
        }
      ],
      otherMenus: [
        { title: 'ลูกหนี้', link: '/', permission: 'false' },
        { title: 'เจ้าหนี้', link: '/', permission: 'false' },
        { title: 'เงินสดเช็ค', link: '/', permission: 'false' },
        { title: 'ซื้อ', link: '/', permission: 'false' },
        { title: 'ขาย', link: '/', permission: 'false' },
        { title: 'โอนสินค้า', link: '/', permission: 'false' },
        { title: 'งานอัตโนมัติ', link: '/', permission: 'false' },
        { title: 'ผลิตสินค้า', link: '/', permission: 'false' },
        { title: 'นับสินค้า', link: '/', permission: 'false' }
      ]
    }
  }
}
</script>

<template>
  <div class="w-100 px-2 mt-2">
    <!-- parent -->
    <div
      v-b-toggle="'entry'"
      class="py-2 px-3 border border-white rounded hoverColorBlackBgWhite"
    >
      <b-icon :icon="'keyboard'"></b-icon>
      <span class="ml-3">{{ 'ป้อนรายการ' }}</span>
    </div>
    <!-- child -->
    <b-collapse :id="'entry'">
      <!-- สมุดรายวัน -->
      <!-- parent -->
      <div v-b-toggle="'สมุดรายวัน'" class="mt-2">
        <span class="pl-3 mr-2"> 1 สมุดรายวัน </span>
        <span>
          <b-icon icon="caret-down-fill"></b-icon>
        </span>
      </div>
      <!-- child -->
      <b-collapse :id="'สมุดรายวัน'">
        <div class="pl-4">
          <div
            v-for="(menu, index) in journalMenus"
            :key="menu.title"
            class="py-1"
            :class="getPermissionClass(menu.permission)"
          >
            <span
              class="hoverColorLightBlueCursorPointer"
              @click="menuLinkClicked(menu.permission, menu.link)"
            >
              1.{{ index + 1 }} {{ menu.title }}
            </span>
          </div>
        </div>
      </b-collapse>

      <!-- อื่น ๆ -->
      <div class="pl-3" v-for="(menu, index) in otherMenus" :key="menu.title">
        <div
          class="py-1"
          :class="getPermissionClass(menu.permission)"
          @click="menuLinkClicked(menu.permission, menu.link)"
        >
          <div>
            <span class="hoverColorLightBlueCursorPointer">
              {{ index + 2 }} {{ menu.title }}
            </span>
          </div>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<style scoped>
.bg-color-1 {
  background-color: #353b50;
}
.image-style {
  width: 40px;
  height: 40px;
}
.b-sidebar-header {
  background-color: #353b50;
}
.hoverColorBlackBgWhite:hover {
  color: black;
  background-color: lightblue;
}
.hoverColorLightBlueCursorPointer:hover {
  color: lightblue;
  cursor: pointer;
}
.disableChoice {
  pointer-events: none;
  opacity: 0.5;
  color: #ccc;
}
.subsubchoice-selection {
  color: white;
}
</style>
