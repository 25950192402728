<script>
import menuMixins from './mixins/menu'

export default {
  mixins: [menuMixins],
  data() {
    return {
      classifyMenus: [
        { title: 'ผังบัญชี', link: '/', permission: 'false' },
        {
          title: 'สรุปผลบัญชีแยกประเภท',
          link: '/report/classify/account-activity',
          permission: 'permission.reportAccountActivity.canView'
        },
        {
          title: 'งบทดลอง',
          link: '/report/classify/trial-balance',
          permission: 'permission.reportTrialBalance.canView'
        },
        {
          title: 'งบดุล',
          link: '/report/classify/balance-sheet',
          permission: 'permission.reportBalanceSheet.canView'
        },
        {
          title: 'งบกำไรขาดทุน',
          link: '/report/classify/profit-and-loss-statement',
          permission: 'permission.reportProfitAndLoss.canView'
        },
        {
          title: 'กระดาษทำการ',
          link: '/report/classify/working-paper',
          permission: 'permission.reportWorkingPaper.canView'
        },
        { title: 'งบการเงินพิเศษอื่น ๆ', link: '/', permission: 'false' },
        { title: 'กู้ยอดคงเหลือผังบัญชี', link: '/', permission: 'false' }
      ],
      otherMenus: [
        { title: 'ลูกหนี้', link: '/', permission: 'false' },
        { title: 'เจ้าหนี้', link: '/', permission: 'false' },
        { title: 'สินค้าคงคลัง', link: '/', permission: 'false' },
        { title: 'สต๊อกการ์ด', link: '/', permission: 'false' },
        { title: 'ภาษีส่งสรรพากร', link: '/', permission: 'false' },
        { title: 'วิเคราะห์ซื้อ', link: '/', permission: 'false' },
        { title: 'วิเคราะห์ขาย', link: '/', permission: 'false' },
        { title: 'ผลิตสินค้า', link: '/', permission: 'false' },
        { title: 'กระดาษนับสินค้า', link: '/', permission: 'false' }
      ]
    }
  }
}
</script>

<template>
  <div class="w-100 px-2 mt-2">
    <!-- parent -->
    <div
      v-b-toggle="'report'"
      class="py-2 px-3 border border-white rounded hoverColorBlackBgWhite"
    >
      <b-icon :icon="'file-text'"></b-icon>
      <span class="ml-3">{{ 'ออกรายงาน' }}</span>
    </div>
    <!-- child -->
    <b-collapse :id="'report'">
      <!-- แยกประเภท -->
      <!-- parent -->
      <div v-b-toggle="'แยกประเภท'" class="mt-2">
        <span class="pl-3 mr-2"> 1 แยกประเภท </span>
        <span>
          <b-icon icon="caret-down-fill"></b-icon>
        </span>
      </div>
      <!-- child -->
      <b-collapse :id="'แยกประเภท'">
        <div class="pl-4">
          <div
            v-for="(menu, index) in classifyMenus"
            :key="menu.title"
            class="py-1"
            :class="getPermissionClass(menu.permission)"
          >
            <span
              class="hoverColorLightBlueCursorPointer"
              @click="menuLinkClicked(menu.permission, menu.link)"
            >
              1.{{ index + 1 }} {{ menu.title }}
            </span>
          </div>
        </div>
      </b-collapse>

      <!-- อื่น ๆ -->
      <div class="pl-3" v-for="(menu, index) in otherMenus" :key="menu.title">
        <div
          class="py-1"
          :class="getPermissionClass(menu.permission)"
          @click="menuLinkClicked(menu.permission, menu.link)"
        >
          <div>
            <span class="hoverColorLightBlueCursorPointer">
              {{ index + 2 }} {{ menu.title }}
            </span>
          </div>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<style scoped>
.bg-color-1 {
  background-color: #353b50;
}
.image-style {
  width: 40px;
  height: 40px;
}
.b-sidebar-header {
  background-color: #353b50;
}
.hoverColorBlackBgWhite:hover {
  color: black;
  background-color: lightblue;
}
.hoverColorLightBlueCursorPointer:hover {
  color: lightblue;
  cursor: pointer;
}
.disableChoice {
  pointer-events: none;
  opacity: 0.5;
  color: #ccc;
}
.subsubchoice-selection {
  color: white;
}
</style>
