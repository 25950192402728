<script>
import AppNavbar from '../navbar/Navbar.vue'
import AppSidebar from '../sidebar/Sidebar.vue'

export default {
  components: {
    AppNavbar,
    AppSidebar
  }
}
</script>

<template>
  <div>
    <app-navbar></app-navbar>
    <app-sidebar></app-sidebar>
  </div>
</template>

<style></style>
