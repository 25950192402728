<script>
import { toastMixins } from '@/mixins'

import BasicMenu from './menus/Basic.vue'
import RegistrationMenu from './menus/Registration.vue'
import EntryMenu from './menus/Entry.vue'
import ReportMenu from './menus/Report.vue'

export default {
  mixins: [toastMixins],
  components: {
    RegistrationMenu,
    EntryMenu,
    ReportMenu,
    BasicMenu
  },
  data() {
    return {
      user: null,
      searchKeyword: null
    }
  },
  created() {
    this.user = this.$store.state.auth.user
  },
  methods: {
    subchoiceClicked(link) {
      if (link && link !== '/') {
        this.$router.push(link)
      }
    },
    getUserPermission(permission) {
      if (_.isBoolean(permission)) return permission
      return _.get(this.user, permission)
    }
  }
}
</script>

<template>
  <b-sidebar
    id="sidebar-backdrop"
    backdrop-variant="dark"
    bg-variant="dark"
    text-variant="light"
    :no-header-close="true"
    width="350px"
    backdrop
    shadow
  >
    <!-- userId -->
    <template v-slot:title>
      <div class="w-100 mt-2">
        <img src="@/assets/h.jpg" alt="happy-soft-logo" class="image-style" />
        <span class="h5 ml-3">{{ user ? user.userId : '-' }}</span>
      </div>
    </template>

    <!-- search input -->
    <div>
      <b-form-group class="m-3">
        <b-form-input
          v-model="searchKeyword"
          placeholder="ค้นหา"
          trim
        ></b-form-input>
      </b-form-group>
    </div>

    <!-- menu -->
    <div class="px-2">
      <basic-menu></basic-menu>
      <registration-menu></registration-menu>
      <entry-menu></entry-menu>
      <report-menu></report-menu>
    </div>
  </b-sidebar>
</template>

<style scoped>
.bg-color-1 {
  background-color: #353b50;
}
.image-style {
  width: 40px;
  height: 40px;
}
.b-sidebar-header {
  background-color: #353b50;
}
.hoverColorBlackBgWhite:hover {
  color: black;
  background-color: lightblue;
}
.hoverColorLightBlueCursorPointer:hover {
  color: lightblue;
  cursor: pointer;
}
.disableChoice {
  pointer-events: none;
  opacity: 0.5;
  color: #ccc;
}
.subsubchoice-selection {
  color: white;
}
</style>
