<script>
import Header from '@/components/layout/header/Header.vue'
import Footer from '@/components/layout/footer/Footer.vue'

export default {
  name: 'Home',
  components: {
    AppHeader: Header,
    AppFooter: Footer
  }
}
</script>

<template>
  <div class="bg">
    <app-header></app-header>
    <app-footer></app-footer>
  </div>
</template>

<style scoped>
.bg {
  /* The image used */
  background-image: url('../assets/Stone-Quarry-Pics-20091007-196.jpg');
  /* Full height */
  height: 120%;
  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
