<script>
import { toastMixins } from '@/mixins'

export default {
  mixins: [toastMixins],
  data() {
    return {
      user: null,
      aboutUs: [
        {
          topic: 'ที่อยู่',
          detail:
            '1057 ถนนพระราม 3 แขวงช่องนนทรี เขตยานนาวา กรุงเทพมหานคร 10120'
        },
        { topic: 'เบอร์โทรศัพท์', detail: '0-2295-1124' },
        { topic: 'Email', detail: 'sale@happysoft.co.th' },
        { topic: 'Line ID', detail: '@happysoft' },
        { topic: 'ก่อตั้ง', detail: '8 ธันวาคม 2553' },
        {
          topic: 'ธุรกิจ',
          detail: 'พัฒนาและวางระบบบัญชีคอมพิวเตอร์แนวใหม่ครบวงจร'
        }
      ]
    }
  },
  computed: {
    displayUserName() {
      const user = this.$store.state.auth.user
      const maxLength = 15
      if (user && _.isString(user.userId)) {
        if (user.userId.trim().length > maxLength) {
          return user.userId.trim().slice(0, maxLength - 3) + '...'
        }
        return user.userId.trim()
      }
      return ''
    }
  },
  mounted() {
    this.user = this.$store.state.auth.user
  },
  methods: {
    menuClicked(type) {
      switch (type) {
        case 'USER':
          this.$router.push('/user/basic-info')
          break
        case 'ADMIN':
          if (this.user && +this.user.userLevel === 99.99) {
            this.$router.push('/admin')
          } else {
            alert('ผู้ใช้งานไม่ได้รับสิทธิ์ให้เข้าถึงเนื้อหาส่วนนี้')
          }
          break
        case 'LOGOUT':
          this.$store.dispatch('logout')
          this.mxDisplaySuccessMessage('ออกจากระบบสำเร็จ')
          setTimeout(
            () => location.reload(),
            this.$store.state.constants.defaultDelayMs
          )
          break
        default:
          break
      }
    }
  }
}
</script>

<template>
  <b-navbar toggleable type="dark" class="bg-color-1" fixed="top">
    <b-nav-form>
      <b-navbar-toggle
        target="nav-collapse"
        v-b-toggle.sidebar-backdrop
      ></b-navbar-toggle>
      <b-navbar-brand href="#">
        <span class="h6 pl-3 d-none d-md-inline-block">
          © 2020 - Happysoft Accounting Co.Ltd.
        </span>
      </b-navbar-brand>
    </b-nav-form>

    <b-nav-form>
      <b-button variant="light" size="sm">
        <b-icon icon="envelope"></b-icon>
        <span class="ml-2 d-none d-md-inline-block">
          ข้อความ
        </span>
      </b-button>
      <b-dropdown
        id="dropdown-right"
        right
        variant="light"
        size="sm"
        class="ml-1"
      >
        <template v-slot:button-content>
          <b-icon icon="chat-left-text"></b-icon>
          <span class="ml-2 d-none d-md-inline-block">
            เกี่ยวกับเรา
          </span>
        </template>
        <!-- <b-dropdown-item> -->
        <div class="m-2">
          <div class="d-flex justify-content-center align-items-center">
            <div class="d-flex justify-content-center align-items-center">
              <img
                style="width: 75%; height: 75%;"
                class="mr-3"
                src="@/assets/h.jpg"
              />
            </div>
            <div class="d-flex flex-column">
              <span class="h6 font-weight-bold">
                บริษัท แฮปปี้ซอฟท์แอคเคาน์ติ้ง จำกัด
              </span>
              <span class="font-weight-lighter">
                Happysoft Accounting Company Limited
              </span>
            </div>
          </div>
          <div class="mt-3 mb-2">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d484.55844034614734!2d100.5464751051476!3d13.690110409726296!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30e29f5f3352592f%3A0xfb210d99aa2046e3!2sFirst%20Love!5e0!3m2!1sen!2sth!4v1614427213575!5m2!1sen!2sth"
              width="400"
              height="300"
              style="border:0;"
              allowfullscreen=""
              loading="lazy"
            >
            </iframe>
          </div>
          <div style="font-size: 0.8rem;">
            <div v-for="item in aboutUs" :key="item.topic" class="d-flex">
              <div
                class="w-25 border px-2 py-1 d-flex align-items-center justify-content-center"
              >
                <span>
                  {{ item.topic }}
                </span>
              </div>
              <div
                class="w-75 border px-2 py-1 text-wrap d-flex align-items-center"
              >
                {{ item.detail }}
              </div>
            </div>
          </div>
        </div>
        <!-- </b-dropdown-item> -->
      </b-dropdown>
      <b-dropdown
        id="dropdown-right"
        right
        variant="light"
        size="sm"
        class="ml-1"
      >
        <template v-slot:button-content>
          <b-icon icon="person-fill"></b-icon>
          <span class="ml-2 d-none d-md-inline-block">{{
            displayUserName
          }}</span>
        </template>
        <b-dropdown-item @click="menuClicked('USER')">
          ข้อมูลส่วนตัว
        </b-dropdown-item>
        <b-dropdown-item
          v-if="user && +user.userLevel === 99.99"
          @click="menuClicked('ADMIN')"
        >
          จัดการองค์กร
        </b-dropdown-item>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item @click="menuClicked('LOGOUT')">
          ออกจากระบบ
        </b-dropdown-item>
      </b-dropdown>
    </b-nav-form>
  </b-navbar>
</template>

<style scoped>
.bg-color-1 {
  background-color: #353b50;
}
.image-style {
  width: 40px;
  height: 40px;
}
.b-sidebar-header {
  background-color: #353b50;
}
.hoverColorBlackBgWhite:hover {
  color: black;
  background-color: lightblue;
}
.hoverColorLightBlueCursorPointer:hover {
  color: lightblue;
  cursor: pointer;
}
.disableChoice {
  pointer-events: none;
  opacity: 0.5;
  color: #ccc;
}
.subsubchoice-selection {
  color: white;
}
</style>
