<script>
import menuMixins from './mixins/menu'

export default {
  mixins: [menuMixins],
  data() {
    return {
      menus: [
        {
          title: 'ข้อมูลของกิจการ',
          link: '/basic/basic-info',
          permission: 'permission.registrationMaster.canView'
        },
        {
          title: 'ตัวเชื่อมโยงไปบัญชีแยกประเภท',
          link: '/',
          permission: 'false'
        },
        { title: 'ตารางงวดบัญชีประจำปี', link: '/', permission: 'false' },
        { title: 'กำหนดเองตามใจชอบ', link: '/', permission: 'false' },
        { title: 'เลขเริ่มต้นเอกสาร', link: '/', permission: 'false' }
      ]
    }
  }
}
</script>

<template>
  <div class="w-100 px-2 mt-2">
    <!-- parent -->
    <div
      v-b-toggle="'basic'"
      class="py-2 px-3 border border-white rounded hoverColorBlackBgWhite"
    >
      <b-icon :icon="'gear'"></b-icon>
      <span class="ml-3">{{ 'ข้อมูลปูพื้นฐาน' }}</span>
    </div>
    <!-- child -->
    <b-collapse :id="'basic'">
      <div class="pl-3" v-for="(menu, index) in menus" :key="menu.title">
        <div
          class="py-1"
          :class="
            `${index === 0 ? 'mt-2' : ''} ${getPermissionClass(
              menu.permission
            )}`
          "
          @click="menuLinkClicked(menu.permission, menu.link)"
        >
          <div>
            <span class="hoverColorLightBlueCursorPointer">
              {{ index + 1 }} {{ menu.title }}
            </span>
          </div>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<style scoped>
.bg-color-1 {
  background-color: #353b50;
}
.image-style {
  width: 40px;
  height: 40px;
}
.b-sidebar-header {
  background-color: #353b50;
}
.hoverColorBlackBgWhite:hover {
  color: black;
  background-color: lightblue;
}
.hoverColorLightBlueCursorPointer:hover {
  color: lightblue;
  cursor: pointer;
}
.disableChoice {
  pointer-events: none;
  opacity: 0.5;
  color: #ccc;
}
.subsubchoice-selection {
  color: white;
}
</style>
