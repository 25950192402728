<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState({
      company: s => s.auth.company
    }),
    getCompanyName() {
      return (this.company && this.company.name) || '-'
    },
    getCompanyAddress() {
      if (!this.company) return '-'

      const { address1, address2, province, postalCode } = this.company

      const handleAddress1 = address1 || ''
      const handleAddress2 = address2 || ''
      const handleProvince = province || ''
      const handlePostalCode = postalCode || ''

      return `${handleAddress1} ${handleAddress2} ${handleProvince} ${handlePostalCode}`
    }
  }
}
</script>

<template>
  <div>
    <b-navbar
      type="dark"
      class="bg-color-1 d-flex flex-column text-white p-0 text-center"
      fixed="bottom"
    >
      <div class="w-100">
        <h5 class="mt-3 mb-1">{{ getCompanyName }}</h5>
      </div>
      <div class="w-100">
        <h6 class="mt-1 mb-3">
          {{ getCompanyAddress }}
        </h6>
      </div>
      <div class="w-100 bg-color-2 py-1">
        <span class="text-white-50 font-style-small">© 2020 Copyright:</span>
        <a
          href="https://happysoft-accounting.com/"
          class="text-white font-style-small"
        >
          Happysoft Accounting (อุตสาหกรรม) Cloud Version 0.1.0
        </a>
      </div>
    </b-navbar>
  </div>
</template>

<style scoped>
.bg-color-1 {
  background-color: #353b50;
}
.bg-color-2 {
  background-color: #272d3d;
}
.font-style-small {
  font-size: 0.9rem;
}
</style>
